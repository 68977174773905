import request from '@/utils/request'
// 原创list
export function list(params) {
  return request({
    url: '/gift/gift/exchangeGift',
    method: 'get',
    params: params
  })
}
// 获取分类id
export function classlist(params) {
  return request({
    url: '/gift/gift/getCategoryList',
    method: 'get',
    params: params
  })
}