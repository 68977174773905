<template>
  <div style="background:#e5e5e5;">
    <div class="bread-line">
      <div class="safe-center">
        兑换页
      </div>
    </div>
    <div class="pay-success">
      <div class="safe-center">
        <img src="@/assets/image/success.png" alt="">
        <p>兑换成功</p>
        <div style="overflow:hidden;width:320px;margin:0 auto;">
          <el-button
            type="danger"
            plain
            style="margin-right:40px;float:left;"
            @click="exchangepage"
          >查看兑换记录</el-button>
          <el-button
            type="primary"
            style="float:left;"
            @click="routerlink"
          >去社区赚筑龙币</el-button>
        </div>
        <div>
          兑换成功后，请耐心等待礼品哦~
        </div>
      </div>
    </div>
    <!-- 更多礼品 -->
    <div>
      <div class="safe-center" style="background:white;padding:45px 0px;">
        <div class="more_gift">
          <p>更多礼品</p>
        </div>
        <ul class="more_list">
          <li
            v-for="(item, index) in morelist"
            :key="index"
            @click="morepage(item.id)"
          >
            <img :src="item.img" alt="">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { list } from '@/api/indexapi'
export default {
  data() {
    return {
      morelist: []
    }
  },
  mounted() {
    var banner = document.getElementById('banner')
    banner.style.display = 'none'
    this.getlist()
  },
  methods: {
    getlist() {
      const id = this.$route.query.gift_source
      const info = {
        is_more: id,
        page: 1
      }
      list(info)
        .then((res) => {
          if (this.$route.query.gift_source == 1) {
            this.morelist = res.result.zlProduct
          } else {
            this.morelist = res.result.jdProduct
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    morepage(giftid) {
      this.$router.push({
        path: 'giftinfo',
        query: { giftid: giftid }
      })
    },
    // 兑换记录
    exchangepage() {
      this.$router.push({
        path: '/ExchangeRecord'
      })
    },
    routerlink() {
      window.open(
        'https://www.zhulong.com/bbs',
        '_blank'
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.bread-line {
  background: rgba(0, 0, 0, 0.5);
  height: 45px;
  line-height: 45px;
  color: white;
  text-align: left;
  margin-bottom: 15px;
  margin-bottom: 20px;
  span {
    padding: 0 10px;
  }
}
.safe-center {
  width: 1200px;
  margin: 0 auto;
}
.pay-success {
  margin-bottom: 30px;
  .safe-center {
    height: 443px;
    background: white;
    padding: 73px 426px 60px 426px;
    text-align: center;
    img {
      width: 90px;
      height: 90px;
      margin-bottom: 30px;
    }
    p {
      font-size: 26px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 38px;
      margin: 0 0 40px 0px;
    }
    div {
      font-size: 18px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: #333333;
      line-height: 38px;
      margin-top: 25px;
    }
  }
}
// 更多礼品
.more_gift {
  height: 50px;
  line-height: 50px;
  font-size: 26px;
  font-family: "MicrosoftYaHei";
  font-weight: bold;
  color: #333333;
  margin-bottom: 45px;
  background: url("../../assets/image/more.png") no-repeat;
  background-size: 100%;
  p {
    width: 170px;
    margin: 0 auto;
    background: white;
    height: 50px;
    line-height: 40px;
  }
  span {
    display: inline-block;
    width: 507px;
    height: 1px;
    background: linear-gradient(-90deg, #ee2e2e);
    background: -webkit-linear-gradient(90deg, #ee2e2e);
    background: -moz-linear-gradient(90deg, #ee2e2e);
    background: -o-linear-gradient(90deg, #ee2e2e);
    background: -ms-linear-gradient(90deg, #ee2e2e);
    background: linear-gradient(90deg, #ee2e2e);
  }
}
.more_list {
  padding: 0 144px;
  overflow: hidden;
  li {
    width: 180px;
    height: 158px;
    margin-bottom: 70px;
    float: left;
    margin-right: 63px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  li:nth-of-type(4n) {
    margin-right: 0px !important;
  }
}
</style>
